import React, { useState, useEffect, useRef } from 'react';

import ModalMessage from './ModalMessage'; 
import Loading      from './Loading';
import ConfirmModal from './ConfirmModal-password';

import * as utils from './Utils';
import { MD5 } from 'crypto-js';

import './App.css';

const Logo = () => {

  return (
    <div className="flex justify-center items-center mb-2">
      <img src="../assets/mdl-logo-circle-bg-white.png" alt="Logo" className="max-w-full h-auto" style={{ maxWidth: '100px' }}/>
    </div>
  );
};

function App() {

  const [loading,  setLoading  ] = useState(true);
  const [formData, setFormData ] = useState({
    cpf   : '',
    email : '',
  });

  const [modalMessage,  setModalMessage] = useState(null);
  const [modalCallback, setModalCalback] = useState(null);

  const [isLoginValid, setIsLoginValid] = useState(true);

  const cpfRef    = useRef(null);
  const emailRef  = useRef(null);
  const submitRef = useRef(null);

  const cartUUID = localStorage.getItem('cartUUID');

  useEffect(() => {

    setLoading(false);

    //setIsLoginValid( utils.validateCPF(formData.cpf) && utils.validatePassword(formData.password) );

    setTimeout(() => {
      
      const mdlCPF = localStorage.getItem('mdlCPF');
      if (mdlCPF)
        formData.cpf = mdlCPF;
      
      if (cpfRef.current)
        cpfRef.current.focus();

    }, 250);

  }, []);

  const handleChange = (name, value, inputRef = null) => {

    const cursorPosition = inputRef ? inputRef.current.selectionStart : null;

    if (name === 'cpf')
      value = value.trim().replace(/\D/g, '');

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (inputRef) {

      inputRef.current.value = value;
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  };

  function openModal(msg, cb = null) {

    setModalMessage(msg);
    setModalCalback(cb);      

    const focusedInput = document.activeElement;
    if (focusedInput)
      focusedInput.blur();
  }

  const handleKeyDown = (e) => {

    if (e.key === 'Enter') {

      e.preventDefault();
      submitRef.current.click();
    }
  };  

  const handleCancel = (e) => {

    e.preventDefault();

    localStorage.setItem('mdlCPF', formData.cpf);

    window.location.href = '/login';
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    if (!utils.validateCPF(formData.cpf)) {

      openModal("CPF inválido.\nPor favor confira os dados.", () => {
        return () => {
          cpfRef.current.focus();
        };        
      });      

      return;
    }

    if (!utils.validateEmail(formData.email)) {

      openModal("Email inválido.\nPor favor confira os dados.", () => {
        return () => {
          emailRef.current.focus();
        };        
      });      

      return;
    }

    //console.log('out: ' + JSON.stringify(formData));

    setLoading(true);

    const landing = localStorage.getItem('loginRedirect');

    const login = {
      cpf:   formData.cpf,
      email: formData.email,
      redir: window.location.origin + '/redefine',
      landing: landing ? landing : '/purchase'
    }

    try {

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/login/recover', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(login),
      });

      const result = await response.json();
      if (!result)
        throw new Error("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.\Status: " + response.status);
      else if (!response.ok)
        throw new Error(result.msg);

      setModalMessage("Para recuperar a senha, siga as instruções enviadas por email.");
      setModalCalback(() => {
        return () => {
          setLoading(false);
          window.location.href = '/login';
        };
      });      
    }
    catch (error) {

      console.error('Error logging in: ', error.message);

      setModalMessage(error.message);
      setModalCalback(() => {
        return () => {
          setLoading(false);
        };
      });
    }    
  };  

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }
  }; 

  const bodyStyle = {
    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  return (
    <div style={bodyStyle}>
      <div className="container bg-white mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <Logo />
            <div>
              <h1 className="text-center text-1xl font-bold mb-4">Recuperação de senha por e-mail:</h1>
            </div>
            <form 
              onSubmit={handleSubmit}
              onKeyDown={handleKeyDown}
              className="grid grid-cols-1 border-gray-300 border-2 rounded bg-gray-100">

              <div className="p-2">
                <label className="block">Usuário (CPF):</label>
                <input ref={cpfRef} type="text" name="username" maxLength="11" placeholder="Digite seu CPF" className="border-2 rounded w-full p-1 mb-2" value={formData.cpf}
                 onChange={(e) => handleChange('cpf', e.target.value)}
                 onBlur={(e) => handleChange('cpf', e.target.value)}
                 onFocus={(e) => handleChange('cpf', e.target.value)}
                 inputMode="numeric"
                 pattern="[0-9]*"
                 required />
                <label className="block">Email:</label>
                <input ref={emailRef} type="text" name="email" maxLength="64" placeholder="Digite o email" className="border-2 rounded w-full p-1" value={formData.email}
                 onChange={(e) => handleChange('email', e.target.value)}
                 onBlur={(e) => handleChange('email', e.target.value)}
                 onFocus={(e) => handleChange('email', e.target.value)}
                 required />
              </div>

              <div className="flex justify-end items-center m-2">

                <button
                  className="bg-gray-400 text-white px-4 py-2 rounded text-xl font-bold mr-2"
                  onClick={handleCancel}
                  style={{ minWidth: '150px' }}>
                  Voltar
                </button>

                <button
                  type="submit"
                  ref={submitRef}
                  className={`bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold ml-2 ${isLoginValid ? '' : 'opacity-50 cursor-not-allowed'}`}
                  onClick={handleSubmit}
                  disabled={!isLoginValid}
                  style={{ minWidth: '150px' }}>
                  OK
                </button>

              </div>

            </form>

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}                       
      </div>
    </div>
  );
}

export default App;
