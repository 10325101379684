import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//https://feathericons.com/
import { Info } from 'feather-icons-react';

import ConfirmModal    from './ConfirmModal-generic';
import ModalMessage    from './ModalMessage'; 
import Loading         from './Loading';
import EventHeader     from './EventHeader'
import EULAModal       from './EulaModal';
import InactivityTimer from './components/InactivityTimer';

import './App.css';

import * as utils from './Utils';

window.addEventListener( "pageshow", function ( event ) {
  var historyTraversal = event.persisted || ( typeof window.performance != "undefined" &&  window.performance.navigation.type === 2 );
  if ( historyTraversal ) { window.location.reload(); } });

const fetchCart = async (uuid) => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/cart/' + uuid, {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();
    return data;
  }
  catch (error) {

    console.error('Error fetching items: ', error);
    throw error;
  }
};
  
function App() {

  const [loading,         setLoading        ] = useState(true);
  const [cart,            setCart           ] = useState({});
  const [modalMessage,    setModalMessage   ] = useState(null);
  const [modalNextFocus,  setModalNextFocus ] = useState(null);
  const [modalCallback,   setModalCalback   ] = useState(null);
  const [isEulaVisible,   setIsEulaVisible  ] = useState(false);
  const [numInstallments, setNumInstallments] = useState(0);

  const eventUUID = localStorage.getItem('eventUUID');
  const cartUUID  = localStorage.getItem('cartUUID' );
  const mdlToken  = localStorage.getItem('mdlToken' );

  useEffect(() => {

    if (!cartUUID || cartUUID.length == 0) {

      console.log("Error: cart not defined.");
      window.location.href = process.env.REACT_APP_LANDING_DEFAULT;
      return;
    }

    const loadPurchasedItems = async () => {

      try {

        //console.log('cartUUID='+cartUUID);

        const cart = await fetchCart(cartUUID);
        if (cart != null && cart.items != null && cart.items.length > 0) {

          if (cart.paymentStatus == 0) {

            if (cart.cartIsFree == 0 && cart.installments != null && cart.installments.length > 1) {

              localStorage.removeItem('mdlCartInstallments');

              setCart(cart);
              setLoading(false);
            }
            else { //cart is free or there are no installments options:

              localStorage.setItem('mdlCartInstallments', 1);
              window.location.href = '/payment';
            }
          }
          else { //already paid:

            localStorage.setItem('mdlCartPaid', cartUUID);
    
            setModalMessage(cart.cartIsFree > 0 ? "Isento de pagamento!" : "Pagamento efetuado!");
            setModalCalback(() => {
              return () => {
                window.location.href = '/participants';
              };
            });        
          }
        }
        else {

          throw new Error('Could not retrieve items information');
        }
      }
      catch (error) {

        console.log("Error: " + error.message);

        setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
        setModalCalback(() => {
          return () => {
            window.location.reload();
          };        
        });
      }
    };

    loadPurchasedItems();

  }, []);

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }

    if (modalNextFocus) {

      modalNextFocus.focus();
      setModalNextFocus(null);
    }
  };
  
  const handlePageTimeout = () => {

    window.location.href = '/' + localStorage.getItem('landingTag');
  }; 

  const handleEulaAgree = (e) => {

    if (numInstallments > 0 && localStorage.getItem('mdlToken') && localStorage.getItem('mdlEmail'))
      window.location.href = '/payment';
    else
      setIsEulaVisible(false);
  };   

  const handleEulaCancel = (e) => {

    setLoading(false);
    setIsEulaVisible(false);
  }

  const handleInstallmentsButtonClick = async (numInstallments) => {

    console.log('Installments: ', numInstallments);

    setNumInstallments(numInstallments);
    localStorage.setItem('mdlCartInstallments', numInstallments);
    
    if (numInstallments > 1)
      setIsEulaVisible(true);
    else
      window.location.href = '/payment';
  }

  const handleInfoButtonClick = async (numInstallments) => {

    setIsEulaVisible(true);
  }

  const bodyStyle = {

    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  const eulaTitle = 'Parcelamento Pix:';

  const eulaText = `
    <p>A entrada você pagará agora e as demais parcelas receberá o código de pagamento via e-mail e SMS.</p>
    <br/>
    <p>Aceite apenas códigos de pagamento que levem ao login oficial do Mundo do Laço (inscricao.mundodolaco.com).</p>
    <br/>
    <p>Certifique-se que o beneficiário do Pix é Mundo do Laco (CNPJ 25.967.661/0001-11) para evitar fraudes.</p>
    <br/>
    <p>A inscrição será considerada válida se todas as parcelas forem pagas até as datas definidas. Caso contrário, o valor da inscrição não será reembolsado, já que os números foram reservados pra você.</p>
  `;    

  return (

    <div style={bodyStyle}>

      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">

      <InactivityTimer 
        timeoutDuration={ 5 * 60 * 1000 /* 5 min @REVIEW@ */ }
        onTimeout={handlePageTimeout}
      />

      {loading && (<Loading />)}
        {!loading && (
          <div>
            <div className="flex justify-center items-center mb-4">
              <EventHeader uuid={eventUUID} />
            </div>
            <div className="mt-20">
            <h1 className="text-center text-1xl font-normal mb-1">Valor total da inscrição:</h1>
            <h1 className="text-center text-1xl font-bold mb-6">{utils.formatCurrency(cart.totalPrice)}</h1>
            </div>

            <h1 className="text-center text-1xl font-normal mb-2">Selecione a forma de pagamento Pix:</h1>

            <ul className={"flex flex-col justify-between items-left mb-6 bg-white p-4 rounded-lg shadow-md border-gray-300 border-2 space-y-4"}
                style={{ userSelect: 'none' }}>
              {cart.installments.map((item) => (
                <li>
                  <div className="flex items-center justify-center">
                    <button className="bg-orange-400 text-white text-s font-semi-bold px-4 py-2 rounded flex items-center justify-center font-medium"
                      onClick={() => handleInstallmentsButtonClick(item.numInstallments)} style={{ minWidth: '300px', maxHeight: '50px' }}>
                      {item.desc}
                    </button>                  
                  </div>
                </li>
              ))}
            </ul>

            <h1 className="text-center text-1xl font-normal mb-6 italic">{cart.pixInterest}</h1>

            <p className="text-center text-1xl mb-6 text-blue-700 font-medium" onClick={() => handleInfoButtonClick()} >
              <Info className="inline h-6 w-6 align-middle"/>&nbsp;Dúvidas sobre o parcelamento Pix?
            </p>

            <div className="flex justify-center items-center mb-4">
              <img src="../assets/pix-bc-logo-0-1024.png" alt="Pix Logo" className="max-w-full h-auto" style={{ maxWidth: '150px' }}/>
            </div>

            {modalMessage && (
              <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
            )}                       

          </div>
        )}

        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}

        {isEulaVisible && <EULAModal eulaTitle={eulaTitle} eulaText={eulaText} onAgree={handleEulaAgree} onCancel={handleEulaCancel} />}

      </div>
    </div>
  );
}

export default App;
