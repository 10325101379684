// ConfirmModal.js
import React, { useState, useEffect, useRef } from 'react';

import * as utils from './Utils';

const ConfirmModal = ({ isOpen, onClose, onConfirm }) => {

  const [code, setCode] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);
  const codeInputRef = useRef(null);

  useEffect(() => {
    setCode('');
    setIsCodeValid(false);
    if (isOpen)
      codeInputRef.current.focus();
  }, [isOpen]);  

  const handleCodeChange = (e) => {
    const enteredCode = e.target.value;
    setCode(enteredCode);
    setIsCodeValid(utils.validateCode(enteredCode));
  };  

  const handleConfirm = () => {
    if (isCodeValid) {
      onConfirm(code);
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleConfirm();
    } else if (e.key === 'Escape') {
      onClose();
    }
  };  

  return (
    <div
      className={`fixed inset-0 ${isOpen ? '' : 'hidden'} flex items-center justify-center m-2`}
      onKeyDown={handleKeyDown}
      tabIndex="0"
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 rounded-lg z-10">

        <h2 className="text-lg font-bold mb-4">Digite o código recebido por SMS</h2>

        <input
          type="text"
          placeholder="Digite o código"
          value={code}
          onChange={handleCodeChange}
          className={`border rounded p-2 mb-4 w-full ${isCodeValid ? 'border-green-500' : 'border-red-500'}`}
          ref={codeInputRef} 
          onKeyDown={handleKeyDown}
          maxLength="6"
          inputMode="numeric"
          pattern="[0-9]*"
        />
        <div className="flex justify-end">
          <button className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2" onClick={onClose}>
            Cancelar
          </button>
          <button 
            className={`bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold ${isCodeValid ? '' : 'opacity-50 cursor-not-allowed'}`}
            onClick={handleConfirm} disabled={!isCodeValid} style={{ minWidth: '150px' }}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
