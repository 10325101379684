export const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
}

export function validateMobileBR(value) {

  const mobileRegex = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[0-9])[0-9]{3}\-?[0-9]{4}$/;
  return (mobileRegex.test(value.trim()));
}

export function validateEmail(value) {

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return (emailRegex.test(value.trim()));
}

export function validatePassword(pw) {

  return pw.length >= 8;
}

export function validateCode(code) {

  return code.length >= 4;
}

export function validateCPF(cpf) {

  const cleanCPF = cpf.replace(/\D/g, '');
  if (cleanCPF.length !== 11)
    return false;

  if (/^(\d)\1+$/.test(cleanCPF))
    return false;

  let sum = 0, remainder;
  for (let i = 1; i <= 9; i++)
    sum += parseInt(cleanCPF[i - 1]) * (11 - i);
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11)
    remainder = 0;

  if (remainder !== parseInt(cleanCPF[9]))
    return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += parseInt(cleanCPF[i - 1]) * (12 - i);
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11)
    remainder = 0;

  if (remainder !== parseInt(cleanCPF[10]))
    return false;

  return true;
}