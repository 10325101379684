import React, { useState, useEffect, useRef } from 'react';

import ModalMessage from './ModalMessage';
import Loading      from './Loading';

import * as utils from './Utils';

import './App.css';

const fetchEventsList = async () => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/events/?token=' + localStorage.getItem('mdlToken') + '&online=1', {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();

    return data;
  }
  catch (error) {

    console.error('Error fetching events list: ', error);
    throw error;
  }
};

function App() {

  const [loading,    setLoading   ] = useState(false);
  const [eventsList, setEventsList] = useState([]);

  const [modalMessage,   setModalMessage  ] = useState(null);
  const [modalNextFocus, setModalNextFocus] = useState(null);
  const [modalCallback,  setModalCalback  ] = useState(null);

  const bodyStyle = {

    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  useEffect(() => {

    if (localStorage.getItem('mdlAdmin') != 1) {

      localStorage.setItem('loginRedirect', '/admin#logged');
      window.location.href = '/login#admin';
      return;
    }

    setLoading(true);

    const loadData = async () => {

      try {

        const data = await fetchEventsList();
        if (data != null && data.items != null) {

          if (data.items.length == 0)
            setModalMessage("Nenhum evento encontrado.");

          setEventsList(data.items);
          setLoading(false);
        }
        else {

          throw new Error('Could not retrieve events list information');
        }
      }
      catch (error) {

        console.log("Error: " + error.message);

        setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
        setModalCalback(() => {
          return () => {
            window.location.reload();
          };        
        });
      }
    };

    loadData();

  }, []);

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }

    if (modalNextFocus) {

      modalNextFocus.focus();
      setModalNextFocus(null);
    }
  }; 

  const Logo = () => {

    return (
      <div className="flex justify-center items-center mb-2">
        <img src="../assets/mdl-logo-circle-bg-white.png" alt="Logo" className="max-w-full h-auto" style={{ maxWidth: '100px' }}/>
      </div>
    );
  };

  const handleSalesReportButtonClick = async (eventUUID) => {

    setLoading(true);

    try {

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/events/' + eventUUID + '/report/sales?token='+localStorage.getItem('mdlToken'), {
        method: 'GET',
        headers: {
          Pragma: 'no-cache',
          'Cache-Control': 'no-cache',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin' : '*'
        },
      });

      if (!response.ok)
        throw new Error('Erro ao baixar o arquivo.');

      let contentDisposition = response.headers.get('content-disposition');
      if (!contentDisposition)
        contentDisposition = response.headers.get('content-type');

      const suggestedFilenameMatch = contentDisposition ? contentDisposition.match(/filename="(.+)"/) : null;
      const suggestedFilename      = suggestedFilenameMatch ? suggestedFilenameMatch[1] : 'downloaded_file';

      const blob = await response.blob();

      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      
      link.href = blobUrl;
      link.download = suggestedFilename;
      document.body.appendChild(link);
      
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    }
    catch (error) {

      console.log("Error in reports: " + error.message);

      setModalMessage(error.message);
      setModalCalback(() => {
        return () => {
          window.location.reload();
        };        
      });
    }
  }

  return (
    <div style={bodyStyle}>
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <Logo />
            
            {eventsList.length>0&&(
              <div>
                <div className="mt-4">
                  <h1 className="text-center text-1xl font-bold mb-4">Eventos com vendas online:</h1>
                </div>
              </div>
            )}

            <ul className="grid grid-cols-1 -mx-2">
              {eventsList.map((item, index1) => (
                <li 
                  className={"flex flex-col justify-between items-left mb-6 bg-white p-4 rounded-lg shadow-md border-gray-300 border-2"}
                  style={{ userSelect: 'none' }}
                >
                  <div>
                    <p className="text-lg font-semibold mb-2">{item.name}</p>
                  </div>

                  <div className="flex justify-center items-center m-2">
                    <button type="submit" className="bg-orange-400 text-white px-4 py-2 rounded text-l font-bold"
                      onClick={() => handleSalesReportButtonClick(item.eventUUID)} style={{ minWidth: '300px' }}>
                      Baixar relatório financeiro
                    </button>
                  </div>

                </li>
              ))}
            </ul>

            <div className="mb-16">
            </div>

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}                       
      </div>
    </div>
  );
}

export default App;