import React, { useState, useEffect, useRef } from 'react';

import ModalMessage from './ModalMessage'; 
import Loading      from './Loading';
import ConfirmModal from './ConfirmModal-password';

import * as utils from './Utils';
import { MD5 } from 'crypto-js';

import './App.css';

const Logo = () => {

  return (
    <div className="flex justify-center items-center mb-2">
      <img src="../assets/mdl-logo-circle-bg-white.png" alt="Logo" className="max-w-full h-auto" style={{ maxWidth: '100px' }}/>
    </div>
  );
};

function App() {

  const [loading,  setLoading  ] = useState(true);
  const [formData, setFormData ] = useState({
    cpf   : '',
    email : '',
  });

  const [modalMessage,  setModalMessage] = useState(null);
  const [modalCallback, setModalCalback] = useState(null);

  useEffect(() => {

    setLoading(false);

  }, []);

  const handleSMS = async (e) => {

    window.location.href = '/recover-mobile';
  }

  const handleEmail = async (e) => {

    window.location.href = '/recover-email';
  }

  const bodyStyle = {
    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  return (
    <div style={bodyStyle}>
      <div className="container bg-white mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <Logo />
            <div>
              <h1 className="text-center text-1xl font-bold mb-4">Como deseja recuperar a sua senha?</h1>
            </div>

            <div className="grid grid-cols-1">

              <div className="flex flex-col justify-between items-left mb-4 rounded-lg">
                <button
                  className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold"
                  onClick={handleSMS}
                  style={{ minWidth: '150px' }}>
                  Código enviado por SMS
                </button>
              </div>

              <div className="flex flex-col justify-between items-left mb-4 rounded-lg">
                <button
                  className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold"
                  onClick={handleEmail}
                  style={{ minWidth: '150px' }}>
                  Código enviado por e-mail
                </button>
              </div>
              
            </div>

          </div>
        )}
      </div>
    </div>
  );
}

export default App;
