import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//https://feathericons.com/
import { Plus, Minus } from 'feather-icons-react';

import ConfirmModal    from './ConfirmModal-generic';
import ModalMessage    from './ModalMessage'; 
import Loading         from './Loading';
import EventHeader     from './EventHeader'
import EULAModal       from './EulaModal';
import InactivityTimer from './components/InactivityTimer';

import './App.css';

import * as utils from './Utils';

window.addEventListener( "pageshow", function ( event ) {
  var historyTraversal = event.persisted || ( typeof window.performance != "undefined" &&  window.performance.navigation.type === 2 );
  if ( historyTraversal ) { window.location.reload(); } });

const fetchEvent = async (uuid, token) => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'events/' + uuid + '?token=' + token + '&showItems=1', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();
    return data;
  }
  catch (error) {

    console.error('Error fetching event: ', error);
    throw error;
  }
};

function App() {

  const [loading,            setLoading         ] = useState(true);
  const [event,              setEvent           ] = useState({});
  const [selectedItems,      setSelectedItems   ] = useState([]);
  const [clickedRow,         setClickedRow      ] = useState(null);
  const [modalMessage,       setModalMessage    ] = useState(null);
  const [modalCallback,      setModalCalback    ] = useState(null);
  const [isEulaVisible,      setIsEulaVisible   ] = useState(false);

  const [confirmMessage,      setConfirmMessage     ] = useState(null);
  const [confirmLabelOk,      setConfirmLabelOk     ] = useState(null);
  const [confirmLabelCancel,  setConfirmLabelCancel ] = useState(null);
  const [confirmHandleOk,     setConfirmHandleOk    ] = useState(null);
  const [confirmHandleCancel, setConfirmHandleCancel] = useState(null);

  const { eventName } = useParams();

  const eventUUID = localStorage.getItem('eventUUID');
  const mdlToken  = localStorage.getItem('mdlToken' );

  const scrollToCenter = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const elementTop = element.getBoundingClientRect().top + window.scrollY;
      const screenCenter = window.innerHeight / 2;
      const targetScrollPosition = elementTop - screenCenter + (element.offsetHeight / 2);
      window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });      
    }
  };

  useEffect(() => {
    window.handleItemDescButtonClick = (buttonId) => { scrollToCenter(buttonId); };
    return () => { delete window.handleItemDescButtonClick; };
  }, []);  

  useEffect(() => {

    if (!eventUUID || eventUUID.length == 0) {

      console.log("Error: event not defined.");
      window.location.href = process.env.REACT_APP_LANDING_DEFAULT;
      return;
    }

    const loadItemCategories = async () => {

      try {

        //console.log('loaded');

        const event = await fetchEvent(eventUUID, mdlToken);

        if (event.status == 'OK') {

          if (event != null && event.items != null && event.items.length > 0) {

            localStorage.setItem('eventUUID', event.eventUUID);

            setEvent(event);
            setLoading(false);

            setTimeout(() => { window.scrollTo(0, 0); }, 50);

            if (localStorage.getItem('mdlCartPaid')) {

              //console.log("mdlCartPaid IS " + localStorage.getItem('mdlCartPaid'));

              { //setup confirmation dialog:

                setConfirmMessage("Há uma inscrição com participantes ainda não cadastrados.\n\nDeseja completá-la agora?");
                setConfirmLabelOk("Completar");
                setConfirmLabelCancel("Não");
                setConfirmHandleOk(() => {
                  return () => {
                    localStorage.setItem('cartUUID', localStorage.getItem('mdlCartPaid'));
                    window.location.href = '/participants';
                    setConfirmMessage(null);
                  };
                });
                setConfirmHandleCancel(() => {
                  return () => {
                    setConfirmMessage(null);
                  };
                });
              }
            }
          }
          else {
  
            throw new Error('Could not retrieve items information');
          }
        }
        else {

          setModalMessage(event.msg);
          setModalCalback(() => {
            return () => {
              window.location.href = '/' + localStorage.getItem('landingTag');
            };
          });
        }
      }
      catch(error) {

        console.log("Error: " + error.message);

        setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
        setModalCalback(() => {
          return () => {
            window.location.href = '/' + localStorage.getItem('landingTag');
          };
        });
      }
    };

    loadItemCategories();

  }, []);

  const handleItemSelection = (itemUUID, quantity) => {

    setClickedRow(itemUUID);

    setSelectedItems(prevItems => {

      const existingItem = prevItems.find(item => item.itemUUID === itemUUID);

      var unitsLeft = 500;
      for(var i = 0; i < event.items.length; i++) {
        if (event.items[i].itemUUID == itemUUID) {
          unitsLeft = event.items[i].unitsLeft;
          break;
        }
      }
      
      if (existingItem) {

        // If the item already exists, update the quantity
        return prevItems.map(item =>
          item.itemUUID === itemUUID
            ? { ...item, quantity: Math.min(Math.max(0, item.quantity + quantity), unitsLeft) }
            : item
        );
      }
      else {

        quantity = Math.min(Math.max(0, quantity), unitsLeft);

        // If the item is new, add it to the array
        return [...prevItems, { itemUUID, quantity }];
      }
    });

    // Reset the clicked row after a short delay to allow the animation to complete
    setTimeout(() => { setClickedRow(null); }, 250);
  };

  const calculateTotalPrice = () => {

    return selectedItems.reduce((total, item) => {
      const f = event.items.find(c => c.itemUUID === item.itemUUID);
      return total + f.price * item.quantity;
    }, 0);
  };

  const calculateTotalQuantity = () => {

    return selectedItems.reduce((total, item) => {
      const f = event.items.find(c => c.itemUUID === item.itemUUID);
      return total + item.quantity;
    }, 0);
  };

  const handleRowClick = (item) => {

    handleItemSelection(item, 1);
  };

  const handleBuyButtonClick = async () => {

    if (calculateTotalQuantity() === 0) {

      setModalMessage("Selecione pelo menos uma categoria antes de prosseguir.");
      return;
    }

    setLoading(true);

    //console.log('out: ' + JSON.stringify(selectedItems));

    try {

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'cart/' + '?token=' + mdlToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: selectedItems,
        }),
      });

      if (!response.ok) {

        if (response.status == 409) {

          const result = await response.json();
          if (!result)
            throw new Error("Result: " + result.msg);

          setModalMessage(result.msg);
          setModalCalback(() => {
            return () => {
  
              window.location.reload();
            };
          });
        }
        else {

          const error = new Error(`HTTP error: ${response.status}`);
          error.message = await response.json();
          throw error;        
        }
      }
      else {

        const result = await response.json();
        if (!result || result.status != 'OK')
          throw new Error("Result: " + result.msg);

        localStorage.setItem('cartUUID', result.cartUUID);
        localStorage.setItem('cartCode', result.cartCode);

        setIsEulaVisible(true);
      }
    }
    catch(error) {

      console.error('Error fetching cart: ', error.message);

      setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
      setModalCalback(() => {
        return () => {
          setLoading(false);
        };
      });
    }
  };

  const handleEulaAgree = (e) => {

    if (localStorage.getItem('mdlToken') && localStorage.getItem('mdlEmail'))
      window.location.href = '/installments';
    else {

      localStorage.setItem('loginRedirect', '/installments');
      window.location.href = '/login';
    }
  };   

  const handleEulaCancel = (e) => {

    setLoading(false);
    setIsEulaVisible(false);
  }

  const handleModalMessageClose = () => {

    setModalMessage(null); // Clear the modal message

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }
  };
  
  const handlePageTimeout = () => {

    window.location.href = '/' + localStorage.getItem('landingTag');
  };  

  const bodyStyle = {
    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };

  const eulaTitle = 'ATENÇÃO!';

  const eulaText = `
      <p>Tenha em mãos o CPF e os nomes dos participantes. Após o pagamento, você terá que preencher esses dados.</p>
      <br/>
      <p>Algumas categorias exigem uma determinada idade no dia do evento. A organização poderá exigir um documento comprovatório com foto de cada participante no local.</p>
      <br/>
      <p>Ao prosseguir com a inscrição, você confirma ter lido, compreendido e concordado com todos os termos e condições acima.</p>
  `;   

  return (
    <div style={bodyStyle}>
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        <InactivityTimer 
          timeoutDuration={ 5 * 60 * 1000 /* 5 min */ }
          onTimeout={handlePageTimeout}
        />
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <div className="flex justify-center items-center mb-4">
              <EventHeader uuid={eventUUID} />
            </div>
            <div className="mt-20">
              {/*event.details && (<div className="text-center text-1xl font-bold mb-2 whitespace-pre-line">
                <h1 className="text-center text-1xl font-bold mb-2 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: event.details }} />
              </div>)*/}
              <div>
                <div className="text-center text-1xl font-bold m-6">SELECIONE AS CATEGORIAS</div>
              </div>
            </div>
            <ul className="grid grid-cols-1 -mx-2">
              {event.items.map(item => (
                <li 
                  id={item.itemUUID}
                  key={item.itemUUID}
                  className={`grid grid-cols-1 mb-6 p-4 rounded-lg shadow-md ${item.isBundle == 1 ? 'border-orange-500 border-4 bg-orange-50' : 'border-gray-300 border-2 g-white'} ${clickedRow === item.itemUUID ? 'blink-bg' : ''}`}
                  style={{ userSelect: 'none' }}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <p className={`text-lg font-semibold ${item.isBundle == 1 ? ' text-orange-600' : 'text-black'}`}
                       nClick={() => handleRowClick(item.itemUUID)}>{item.name.toUpperCase()}</p>
                    </div>
                    <div className="flex items-center">

                      {(item.price > 0 && item.noDirectSales == 0) && (
                        <p className="text-center text-gray-600 mr-2 ml-2">{utils.formatCurrency(item.price)}</p>
                      )}

                      {(item.price == 0 && item.noDirectSales == 0) && (
                        <span className="text-l mx-2 text-green-600>">Isento</span>
                      )}

                      {(item.unitsLeft > 0 && item.noDirectSales == 0) && (
                      <div className="flex quantity-control">
                        <button
                          className="bg-orange-400 text-white px-2 py-1 rounded"
                          onClick={() => handleItemSelection(item.itemUUID, -1)}
                        >
                          <Minus size={16}/>
                        </button>
                        <span style={{ minWidth: '16px' }} className="text-center text-xl font-bold mx-2">{selectedItems.find(sel => sel.itemUUID === item.itemUUID)?.quantity || 0}</span>
                        <button
                          className="bg-orange-400 text-white px-2 py-1 rounded"
                          onClick={() => handleItemSelection(item.itemUUID, 1)}
                        >
                          <Plus size={16}/>
                        </button>
                      </div>)}

                      {(item.unitsLeft <= 0 || item.noDirectSales != 0)&& (
                      <div className="flex quantity-control">
                        <span className={`text-l font-bold mx-2 ${item.noDirectSales > 0 ? 'text-green-600' : 'text-red-600'}`}>{item.noDirectSales > 0 ? "Informativo":"Esgotado"}</span>
                      </div>)}

                    </div>
                  </div>

                  {item.desc && (
                    <div className="flex justify-between items-center mt-3 shadow-md border border-grey-300 bg-white">
                      <div className="mx-3 my-1 whitespace-pre-line">
                        <div className="item-desc" dangerouslySetInnerHTML={{ __html: item.desc }} />
                      </div>
                    </div>)
                  }

                </li>
              ))}
            </ul>

            <div className="mb-20">

              {event.supportWhatsapp && (
                <div className="text-center text-1xl font-bold mb-2 whitespace-pre-line">
                  <a style={{color:"green"}} href={"https://wa.me/" + event.supportWhatsapp + "?text=" + "Olá! Tenho uma dúvida sobre o evento " + event.name}>
                    <img align="center" style={{height:"50px",padding:"0px 10px",display:"inline"}} src="assets/whatsapp-icon-green.png" />Dúvidas? Fale por Whatsapp aqui!
                  </a>
                </div>
              )}

              {/*event.support && (<div className="text-center text-1xl font-bold mb-2 whitespace-pre-line">
                <h1 className="text-center text-1xl font-bold mb-2 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: event.support }} />
              </div>)*/}

            </div>

            <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-300">
              <div className="container bg-white mx-auto p-4 max-w-screen-md">
                <div className="flex justify-end items-center">
                  <p className="text-xl font-bold mr-4">Total: {utils.formatCurrency(calculateTotalPrice())}</p>
                  <button className="bg-orange-400 text-white text-xl font-bold px-4 py-2 rounded"
                    onClick={handleBuyButtonClick} style={{ minWidth: '150px' }}>
                    Prosseguir
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}

        {confirmMessage && (
          <ConfirmModal message={confirmMessage} okLabel={confirmLabelOk} cancelLabel={confirmLabelCancel} onOk={confirmHandleOk} onCancel={confirmHandleCancel} />
        )}

        {isEulaVisible && <EULAModal eulaTitle={eulaTitle} eulaText={eulaText} onAgree={handleEulaAgree} onCancel={handleEulaCancel} />}

      </div>
    </div>
  );
}

export default App;
